import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

import { richTextBody, useRichText } from "utils"

const IntegrationTemplateBestPractices = ({
  title,
  body,
  // ctas,
}) => {
  const renderBody = useRichText({ text: body })

  return (
    <StyledIntegrationTemplateBestPracticesHero>
      <VerticalSection
        align="left"
        title={title}
        titleAs="h2"
        custom={() => renderBody}
      />
    </StyledIntegrationTemplateBestPracticesHero>
  )
}

const StyledIntegrationTemplateBestPracticesHero = styled.div`
  --tg-max-width: 650px;
  max-width: 1080px;
  margin: 0 auto;

  .customWrap {
    ${richTextBody};

    ul {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--sp-32);
    }
  }
`

export default IntegrationTemplateBestPractices
