import React from "react"
import styled from "styled-components"
import map from "lodash/map"

import { VerticalSection, ValueProp } from "components/common"
import { device, featureGlass } from "src/utils"

const IntegrationTemplateData = ({
  title,
  valueProps,
  // ctas,
}) => {
  const renderGrid = () => (
    <IntegrationTemplateDataVP>
      {map(valueProps, (vp) => (
        <ValueProp
          key={vp.id}
          {...vp}
          media={{ desktop: vp.icon }}
          titleAs="p"
          subtitleAs="pSmall"
          orientation="horizontal"
        />
      ))}
    </IntegrationTemplateDataVP>
  )

  return (
    <StyledIntegrationTemplateDataHero>
      <VerticalSection
        align="left"
        title={title}
        grid={renderGrid}
        titleAs="h2"
      />
    </StyledIntegrationTemplateDataHero>
  )
}

const StyledIntegrationTemplateDataHero = styled.div`
  --tg-max-width: 650px;
  --section-max-width: 1080px;
  ${featureGlass};
`

const IntegrationTemplateDataVP = styled.ul`
  display: grid;
  grid-gap: var(--sp-48);
  grid-template-columns: 1fr;

  @media ${device.laptop} {
    max-width: 800px;
    grid-template-columns: repeat(2, 1fr);
  }

  .vp_contentWrap {
    grid-template-columns: 25px auto;
    grid-gap: var(--sp-16);
  }

  .title {
    font-weight: 500;
    line-height: 1;
  }
  .subtitle {
    font-weight: normal;
    line-height: 28px;
  }
`

export default IntegrationTemplateData
