import React from "react"
import styled from "styled-components"
import map from "lodash/map"

import { VerticalSection, Card } from "components/common"
import { device } from "src/utils"

const IntegrationTemplaceContacts = ({
  title,
  valueProps,
  // ctas,
}) => {
  const renderGrid = () => (
    <IntegrationTemplaceContactsVP>
      {map(valueProps, (vp) => (
        <Card key={vp.id} {...vp} background />
      ))}
    </IntegrationTemplaceContactsVP>
  )

  return (
    <StyledIntegrationTemplaceContactsHero>
      <VerticalSection
        align="left"
        title={title}
        grid={renderGrid}
        titleAs="h2"
      />
    </StyledIntegrationTemplaceContactsHero>
  )
}

const StyledIntegrationTemplaceContactsHero = styled.div`
  --tg-max-width: 650px;
  --section-max-width: 1080px;
  /* max-width: 1080px;
  margin: 0 auto; */
`

const IntegrationTemplaceContactsVP = styled.ul`
  display: grid;
  grid-gap: var(--sp-16);
  grid-template-columns: 1fr;

  @media ${device.laptop} {
    grid-template-columns: repeat(2, minmax(auto, 400px));
  }

  .vp_contentWrap {
    /* grid-template-columns: 25px auto; */
    /* grid-gap: var(--sp-16); */

    .arrow {
      transition: 0;
    }

    &:hover {
      opacity: 0.8;

      svg {
        transform: translateX(10px);
      }
    }
  }

  .title {
    font-weight: 500;
    /* line-height: 1; */
  }
  .subtitle {
    font-weight: normal;
    line-height: 28px;
  }

  .cta {
    /* margin-top: auto; */
  }
`

export default IntegrationTemplaceContacts
