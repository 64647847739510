import React from "react"
import styled from "styled-components"

import { graphql } from "gatsby"
import { Layout, Seo, Nav, VerticalSection } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import { IntegrationsHero, IntegrationGrid } from "../sections/integrations"

import { CtaGetDemo } from "components/cta"

const Integrations = ({ data: { integration } }) => {
  const { integrations_hero, cta_get_demo } = useContentfulSection(
    integration.sections
  )

  const meta = useMeta(integration)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <IntegrationsHero {...integrations_hero} />

        <VerticalSection align="left" custom={() => <IntegrationGrid />} />

        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  --section-max-width: 1080px;

  .customWrap {
    margin-top: 0;
  }
`

export const query = graphql`
  {
    integration: contentfulPage(pageId: { eq: "integration" }) {
      ...Page
    }
  }
`

export default Integrations
