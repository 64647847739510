import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import { HSection, ValueProp } from "components/common"

import { device, glassStyle } from "utils"

const IntegrationTemplateHero = ({
  hatIcon,
  hat,
  title,
  subtitle,
  media,
  valueProps,
}) => {
  const sectionMinHeight = media.desktop.file.details.image.height

  const renderValueProps = () =>
    map(valueProps, (vp) => (
      <ValueProp titleAs="p" subtitleAs="pSmall" key={vp.id} {...vp} />
    ))

  return (
    <StyledIntegrationTemplateHero sectionMinHeight={sectionMinHeight}>
      <HSection
        hatIcon={hatIcon}
        hat={hat}
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        media={media}
        mobileFull
        full
      />
      <div className="heroVp">
        <ul>{renderValueProps()}</ul>
      </div>
    </StyledIntegrationTemplateHero>
  )
}

const StyledIntegrationTemplateHero = styled.div`
  --section-padding-top: 0;
  --section-padding-bottom: 0;
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --tg-hat-color: var(--water);
  --section-max-width: 1080px;

  @media ${device.laptop} {
    --tg-max-width: 465px;
  }

  .half-side-container {
    @media ${device.laptop} {
      margin-bottom: -48px;
    }
    .horizontal_textSection {
      @media ${device.laptop} {
        margin-top: 160px;
        margin-bottom: 200px;
      }
    }

    .hSection-media {
      img {
        @media ${device.laptop} {
          right: 5vw;
        }
        @media ${device.desktop} {
          right: 15vw;
        }
        @media ${device.desktopL} {
          right: 30vw;
        }
      }
    }
  }

  /* padding: 0 var(--section-mobile-padding); */
  .heroVp {
    ${glassStyle};
    position: relative;
    z-index: 4;
    border-radius: 0;
    padding: var(--sp-mobile-section) var(--sp-24);

    @media ${device.laptop} {
      padding: var(--sp-section) 0;
    }
    ul {
      display: grid;
      grid-gap: var(--sp-16);
      max-width: 1080px;
      margin: 0 auto;

      grid-template-columns: 1fr;
      grid-row-gap: 70px;
      @media ${device.laptop} {
        grid-template-columns: repeat(4, minmax(240px, 1fr));
      }

      .tgWrap {
        .title {
          font-weight: 500;
        }
        .subtitle {
          font-weight: normal;
        }
      }
    }
  }
`

export default IntegrationTemplateHero
