import React from "react"
import styled from "styled-components"

import { HSection } from "components/common"

import { device } from "utils"

const hatColor = {
  "quickbooks-online": "var(--quickbooks)",
}

const IntegrationTemplateHero = ({
  hat,
  title,
  subtitle,
  media,
  // logoSmall,
  slug,
}) => {
  const sectionMinHeight = media.desktop.file.details.image.height

  return (
    <StyledIntegrationTemplateHero
      sectionMinHeight={sectionMinHeight}
      slug={slug}
    >
      <HSection
        // hatIcon={{ desktop: logoSmall }}
        hat={hat}
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        media={media}
        full
        mobileFull
      />
    </StyledIntegrationTemplateHero>
  )
}

const StyledIntegrationTemplateHero = styled.div`
  --section-padding-top: 0;
  --section-padding-bottom: 0;
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-max-width: 1080px;

  @media ${device.laptop} {
    --tg-max-width: 460px;
  }

  /* hero image offset... */
  margin-bottom: -112px;
  .horizontal_textSection {
    @media ${device.laptop} {
      margin-bottom: 112px;
    }
  }

  .half-side-container {
    .horizontal_textSection {
      /* --tg-hat-color: ${({ slug }) => hatColor[slug]}; */
      --tg-hat-color: var(--spruce);
    }

    .horizontalWrap_image {
      padding: 0;
    }
  }
`

export default IntegrationTemplateHero
