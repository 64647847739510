import React from "react"
import styled from "styled-components"

import { Section, Testimonials } from "components/common"

import { useInView } from "utils"

const IntegrationTestimonials = ({ testimonials }) => {
  const { ref, isVisible } = useInView()

  return (
    <StyledIntegrationTestimonials ref={ref}>
      <Section align="center">
        <Testimonials testimonials={testimonials} isVisible={isVisible} />
      </Section>
    </StyledIntegrationTestimonials>
  )
}

const StyledIntegrationTestimonials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  --section-padding-top: 0;
  --section-mobile-padding-top: 80px;

  .testimonials {
    align-self: center;
  }
`

export default IntegrationTestimonials
